import React, { useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-solid-svg-icons'

import {
  CentreUnit,
  escapeSlashes,
  formatDate,
  getSubTitleByUnit,
  Title,
} from '../../../../../common'

interface Props {
  viewOnlyState: string
  unit: CentreUnit
}

export const SubjectPageHeaderReadOnly: React.FC<Props> = ({
  viewOnlyState,
  unit,
}) => {
  const lastUpdatedString = useMemo(
    () =>
      escapeSlashes(
        formatDate(new Date(unit?.lastUpdated || Date.now()), true, true),
        ' / '
      ),
    [unit]
  )

  return (
    <Row>
      <Col>
        <Title
          title={unit?.unitName || ''}
          subTitle={getSubTitleByUnit(unit)}
          ancillery={`${unit?.totalCandidates} candidates`}
          titleColor={viewOnlyState === 'approved' ? 'success' : 'primary'}
        />
      </Col>
      <Col className="text-right">
        <Label
          className="d-flex justify-content-end font-weight-bold font-normal"
          style={{ lineHeight: '16px' }}
        >
          <FontAwesomeIcon className="mr-2" icon={faEye} />
          This is a view only mode
        </Label>
        <Label
          className="d-flex justify-content-end opacity-59 font-weight-semi-bold"
          style={{ lineHeight: '16px' }}
        >
          Last updated on {lastUpdatedString}
        </Label>
      </Col>
    </Row>
  )
}
