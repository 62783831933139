import React, { useMemo } from 'react'
import { Row } from 'reactstrap'
import {
  CentreUnit,
  ColumnMap,
  COLUMNS_DEFAULT,
  SORTABLE_COLUMN,
  SortCell,
  TextAlign,
} from '../../../../common'
import { candidateColumnSizes } from '../../constants'

import './candidates-sorter.scss'

interface Props {
  currentSorting: string
  filter: string
  showBottomBorder?: boolean
  toggleSorting: (f: string) => void
  unit: CentreUnit
}

export const CandidatesSorter: React.FC<Props> = ({
  currentSorting,
  filter,
  showBottomBorder,
  toggleSorting,
}): JSX.Element => {
  const sorting: ColumnMap = useMemo(
    () => ({
      ...(filter === 'ALL' ? COLUMNS_DEFAULT : SORTABLE_COLUMN),
    }),
    [filter]
  )

  const [key, direction] = currentSorting.split('-')
  const alignSortCellText = (index: number): TextAlign => {
    if (index === 0) return TextAlign.LEFT
    else return TextAlign.CENTER
  }

  return (
    <Row
      className={`sorter-container px-25 py-25 ${
        showBottomBorder ? 'border-bottom' : ''
      }`}
    >
      {Object.keys(sorting).map((it, idx) => {
        const title =
          direction === 'up' && key === it
            ? sorting[it].titleAlt || sorting[it].title
            : sorting[it].title

        const upDownActive = key === it ? direction : ''

        return (
          <SortCell
            key={`candidate-sorter-${it}`}
            title={title}
            textAlign={alignSortCellText(idx)}
            upDownActive={upDownActive}
            sortable={!!sorting[it].sortable}
            size={
              (candidateColumnSizes[Object.keys(sorting).length] || [])[idx]
            }
            toggle={() => sorting[it].sortable && toggleSorting(it)}
          />
        )
      })}
    </Row>
  )
}
