import React from 'react'

interface Props {
  searchTerm?: string
}

export const NoSearchResults: React.FC<Props> = ({ searchTerm }) => (
  <>
    <p data-testid="no-search-results">
      0 results found{' '}
      {!!searchTerm && (
        <span>
          for <strong>{searchTerm}</strong>
        </span>
      )}
      .
    </p>
    <p>Please refer to the mapping grid or try searching for something else.</p>
  </>
)
