import React, { useMemo } from 'react'
import { Col, Container, Row } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import {
  DownloadButton,
  isCentreUser,
  isHOC,
  useAuth0,
  usePresignedDownload,
} from '../../../../common'

import hocIntroContent from './hocIntroContent.md'
import introContent from './introContent.md'

interface Props {
  centreId: string
}

export const CentreUnitPageHeader: React.FC<Props> = ({
  centreId,
}): JSX.Element => {
  const { user } = useAuth0()

  const content = useMemo(() => {
    return user && isHOC(user) ? hocIntroContent : introContent
  }, [user])

  const {
    pending: centreGradeReportPending,
    download: downloadCentreGradeReport,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/csv`,
    fileName: 'centre-grade-report.csv',
  })

  return (
    <div className="bg-light py-45 px-3 mb-5">
      <Container>
        <Row className="mb-1">
          <Col lg={12}>
            <div
              className="markdown-content"
              data-testid="centre-unit-intro-content"
            >
              <ReactMarkdown source={content} />
            </div>
          </Col>
          {user && isCentreUser(user) && (
            <Col className="text-right">
              <DownloadButton
                pending={centreGradeReportPending}
                download={downloadCentreGradeReport}
                dataTestid="centre-unit-grade-report-download-button"
                inline
              >
                Centre grade report
              </DownloadButton>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            {user && isHOC(user) ? (
              <strong>
                If you need to make any changes after you have submitted your
                grades, please contact us.
              </strong>
            ) : (
              <ul className="list-inline font-weight-bold ">
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Download or upload
                  templates
                </li>
                <li className="list-inline-item mr-5">
                  <span className="mr-2">&bull;</span> Enter or edit grades
                </li>
                <li className="list-inline-item mr-3">
                  <span className="mr-2">&bull;</span> Send for review and
                  approval
                </li>
              </ul>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
