import React from 'react'
import { AddUserEvents, ROLES, UserData } from '../../common'

export const ADD_USER_MODAL_DATA_MAP = {
  [AddUserEvents.SAME_CENTRE_SAME_ROLE]: (data: UserData) => ({
    title: 'This user already exists',
    description: (
      <div>
        This user already has the role of <b>{data.userRole}</b> at this centre.{' '}
        You can resend their invitation in the managing users area.
      </div>
    ),
    subDescription: null,
    confirmText: null,
    cancelText: 'OK',
  }),
  [AddUserEvents.SAME_CENTRE_NEW_ROLE]: (data: UserData) => ({
    title: `Changing ${data.userRole} role to ${data.role}`,
    description: (
      <div>
        This user currently has the <b>{data.userRole}</b> role in this centre.
        {data.userRole === ROLES.HOC
          ? ' Without the Head of Centre role, you will not be able to submit grades to us.'
          : ''}
      </div>
    ),
    subDescription: (
      <u className="d-block">
        Please confirm you want to change their role to Teacher / Additional
        Assessment Materials.
      </u>
    ),
    confirmText: 'Yes, change role',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_SAME_ROLE]: (data: UserData) => ({
    title: `Adding a new centre (${data.centreId}) for user`,
    description: (
      <div>
        This user already has the <b>{data.userRole}</b> role in the following{' '}
        centres: {(data.userCentres || []).join(', ')}.
      </div>
    ),
    subDescription: (
      <u className="d-block">Please confirm you want to add a new centre.</u>
    ),
    confirmText: 'Yes, add centre',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_NEW_ROLE]: (data: UserData) => ({
    title: 'Users can only have one role',
    description: (
      <div>
        This user already has the <b>{data.userRole}</b> role in the following
        centres: {(data.userCentres || []).join(', ')}. You cannot change their
        role to <b>{data.role} </b> at this centre.
      </div>
    ),
    subDescription: null,
    confirmText: null,
    cancelText: 'Cancel',
  }),
}
