import React, { FC, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { RouteComponentProps, Switch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { SpecificationFiles } from '../specification-files'
import { SpecificationToQualification } from '../specifications-to-qualification'
import { SubjectPage } from '../subject-page'
import { NavBar } from '../navbar'
import { HomePage } from '../home'
import { PrivateRoute } from '../private-route'
import { CentreUnitPageWithRouteAndExpiration } from '../centre-unit-page'
import {
  ManageAAMPageWithExpiration,
  ManageAAMPageWithRouteAndExpiration,
  ManageAdminsPageWithRouteAndExpiration,
  ManageSignOffPageWithExpiration,
  ManageSignOffPageWithRouteAndExpiration,
  ManageTeachersPageWithExpiration,
  ManageTeachersPageWithRouteAndExpiration,
} from '../pages'
import {
  BackButtonProvider,
  isAdmin,
  isCAAdmin,
  Loading,
  NotFound,
  ROLES,
  SystemAvailabilityEnum,
  useAuth0,
  useAvailability,
  useConfig,
  useExpiryPage,
  useTracking,
} from '../../common'
import {
  ExpiryHoldingPage,
  HoldingPage,
  ServerErrorHoldingPage,
  SystemClosedHoldingPage,
} from '../holding-page'
import Route from '../route/route'
import { AdditionalAssessmentMaterial } from '../additional-assessment-material'
import { UnitCodes } from '../unit-codes'
import { CambridgeAdminControls } from '../ca-admin-controls'
import { HelpAndContact } from '../help-and-contact'

export const RoutesWithHeader: FC = (): JSX.Element => {
  useTracking(process.env.REACT_APP_GA_TRACKING_CODE)

  const { user, currentCentre, isAuthenticated } = useAuth0()
  const { config, loadSystemAvailabilityConfig } = useConfig()

  const {
    aamManagementAvailable,
    userManagementAvailable,
    gradingAvailable,
  } = useAvailability()

  const [
    aamAvailabilityExpired,
    captureAvailabilityClosed,
    captureAvailabilityExpired,
  ] = useExpiryPage()
  const [serverError, setServerError] = useState<any | null>(null)

  const captureIsClosed =
    captureAvailabilityClosed || captureAvailabilityExpired

  useEffect(() => {
    axios.interceptors.response.use(undefined, function (error) {
      if (`${error?.response?.status}`.startsWith('5')) {
        // 5xx error code
        setServerError(error)
      }
      return Promise.reject(error)
    })
  }, [])

  useEffect(() => {
    if (config.systemAvailability === SystemAvailabilityEnum.SYSTEM_ERROR) {
      setServerError(SystemAvailabilityEnum.SYSTEM_ERROR)
    }
  }, [config.systemAvailability])

  const rules = useMemo(() => {
    const aam = (user && isCAAdmin(user)) || aamManagementAvailable

    const manageAAM = user && (isCAAdmin(user) || isAdmin(user))

    const centres = gradingAvailable

    const manageUser = userManagementAvailable

    return {
      aam,
      manageAAM,
      centres,
      manageUser,
    }
  }, [
    isAuthenticated,
    user,
    aamManagementAvailable,
    userManagementAvailable,
    gradingAvailable,
  ])

  useEffect(() => {
    if (isAuthenticated) {
      loadSystemAvailabilityConfig()
    }
  }, [isAuthenticated])

  const renderedContent = useMemo(() => {
    if (serverError) {
      return <ServerErrorHoldingPage />
    }
    if (!config.siteEnabled) {
      return <HoldingPage />
    }
    if (!config.systemAvailability) {
      return (
        <Container>
          <Loading className="mt-5 d-block mx-auto" />
        </Container>
      )
    }
    if (config.systemAvailability === SystemAvailabilityEnum.CAPTURE_ERROR) {
      return (
        <PrivateRoute>
          <ServerErrorHoldingPage />;
        </PrivateRoute>
      )
    }
    if (config.systemAvailability === SystemAvailabilityEnum.CLOSED) {
      return <SystemClosedHoldingPage />
    }
    return (
      <Switch>
        <Route exact path="/">
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        </Route>

        {rules.aam && [
          <Route key="aam-1" exact path="/aam">
            <PrivateRoute>{<AdditionalAssessmentMaterial />}</PrivateRoute>
          </Route>,
          <Route
            key="aam-2"
            exact
            path="/aam/:qualification"
            render={(props: RouteComponentProps<any>) => (
              <PrivateRoute>
                <SpecificationToQualification
                  qualification={props.match.params.qualification}
                />
              </PrivateRoute>
            )}
          />,
          <Route
            key="aam-3"
            exact
            path="/aam/:qualification/:specification"
            render={(props: RouteComponentProps<any>) => (
              <PrivateRoute>
                <UnitCodes
                  qualification={props.match.params.qualification}
                  //todo need refactor
                  specification={props.match.params.specification.replaceAll(
                    '%2F',
                    '/'
                  )}
                />
              </PrivateRoute>
            )}
          />,
          <Route
            key="aam-4"
            exact
            path="/aam/:qualification/:specification/:unitCode"
            render={(props: RouteComponentProps<any>) => (
              <PrivateRoute>
                <SpecificationFiles
                  qualification={props.match.params.qualification}
                  //todo need refactor
                  specification={props.match.params.specification.replaceAll(
                    '%2F',
                    '/'
                  )}
                  unitCode={props.match.params.unitCode.replaceAll('%2F', '/')}
                />
              </PrivateRoute>
            )}
          />,
        ]}

        <Route exact path="/centres">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            <CambridgeAdminControls />
          </PrivateRoute>
        </Route>
        {!rules.centres && user && isCAAdmin(user) && (
          <Redirect
            exact
            from="/centres/:id"
            to="/centres/:id/manage/teachers"
          />
        )}
        {!rules.centres && rules.manageUser && user && isAdmin(user) && (
          <Redirect exact from="/centres/:id" to="/manage/teachers" />
        )}
        {rules.centres && [
          <Route key="centres-1" exact path="/centres/:id">
            <PrivateRoute>
              <CentreUnitPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            </PrivateRoute>
          </Route>,
          <Route key="centres-2" exact path="/centres/:id/unit/:unitId">
            <PrivateRoute>
              <SubjectPage />
            </PrivateRoute>
          </Route>,
        ]}

        {rules.manageAAM && [
          <Route key="manageaam-1" exact path="/manage/aam">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageAAMPageWithExpiration
                  centreId={currentCentre}
                  isExpired={aamAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route key="manageaam-2" exact path="/centres/:id/manage/aam">
            <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
              {user && (
                <ManageAAMPageWithRouteAndExpiration
                  isExpired={aamAvailabilityExpired}
                />
              )}
            </PrivateRoute>
          </Route>,
        ]}

        {rules.manageUser && [
          <Route exact path="/manage/teachers">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageTeachersPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureIsClosed}
                />
              )}
            </PrivateRoute>
          </Route>,
          <Route exact path="/manage/signoff">
            <PrivateRoute roles={[ROLES.Admin]}>
              {currentCentre && (
                <ManageSignOffPageWithExpiration
                  centreId={currentCentre}
                  isExpired={captureIsClosed}
                />
              )}
            </PrivateRoute>
          </Route>,
        ]}

        <Route exact path="/centres/:id/manage/teachers">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageTeachersPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>
        <Route exact path="/centres/:id/manage/signoff">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageSignOffPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>
        <Route exact path="/centres/:id/manage/admins">
          <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
            {user && (
              <ManageAdminsPageWithRouteAndExpiration
                isExpired={captureIsClosed}
              />
            )}
          </PrivateRoute>
        </Route>

        <Route exact path="/help-and-contact">
          <PrivateRoute>
            <HelpAndContact />
          </PrivateRoute>
        </Route>

        {(aamAvailabilityExpired || captureIsClosed) && (
          <Route component={ExpiryHoldingPage} />
        )}

        <Route component={NotFound} />
      </Switch>
    )
  }, [
    aamAvailabilityExpired,
    captureAvailabilityExpired,
    config.siteEnabled,
    config.systemAvailability,
    currentCentre,
    rules.aam,
    rules.centres,
    rules.manageAAM,
    rules.manageUser,
    serverError,
    user,
  ])

  return (
    <div style={{ marginBottom: '5rem' }}>
      <BackButtonProvider>
        <NavBar />
        {renderedContent}
      </BackButtonProvider>
    </div>
  )
}
