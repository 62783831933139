import React from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faBookOpen,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { faShield } from '@fortawesome/pro-solid-svg-icons'

export const HelpAndContact: React.FC = (): JSX.Element => {
  const resourcesFaqUri =
    'https://www.ocr.org.uk/everything-you-need-to-know-for-summer-2021/grade-submission-system/'
  const privacyPolicyUri =
    'https://www.ocr.org.uk/about/our-policies/website-policies/privacy-policy/'
  const tandcUri =
    'https://www.ocr.org.uk/about/our-policies/website-policies/terms-and-conditions/'

  return (
    <>
      <div className={`px-3 mb-5 bg-light py-45`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="h4 font-weight-bold text-primary">
                Help and Contact
              </div>
              <>
                <div>For support and guidance, please see the OCR website:</div>
              </>
              <ListGroup className="mt-4">
                <ListGroupItem className="border-0 bg-transparent px-0 py-1 font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={resourcesFaqUri}
                    to={resourcesFaqUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      fixedWidth
                      className="mr-3"
                    />{' '}
                    <u>Visit OCR website</u>
                  </ReactGA.OutboundLink>{' '}
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={privacyPolicyUri}
                    to={privacyPolicyUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faShield}
                      fixedWidth
                      className="mr-3"
                    />
                    Privacy Policy
                  </ReactGA.OutboundLink>
                </ListGroupItem>
                <ListGroupItem className="border-0 bg-transparent px-0 py-1  font-weight-bold">
                  <ReactGA.OutboundLink
                    eventLabel={tandcUri}
                    to={tandcUri}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faBookOpen}
                      fixedWidth
                      className="mr-3"
                    />
                    Terms and Conditions
                  </ReactGA.OutboundLink>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <p className="font-weight-bold mb-3">
          If you require any assistance please contact us here.
        </p>
        <Row>
          <Col xs="auto">
            <Button
              tag="a"
              href="mailto:support@ocr.org.uk"
              color="lightButNotTooLight font-weight-bold text-secondary"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <div>support@ocr.org.uk</div>
            </Button>
          </Col>
          <Col xs="auto">
            <Button color="lightButNotTooLight font-weight-bold text-secondary">
              <FontAwesomeIcon icon={faPhone} />
              <div>Phone (01223 553 998)</div>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
