import React from 'react'
import ReactMarkdown from 'react-markdown'

import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import availabilityExpiredContent from './availability-expired-content.md'

export const ExpiryHoldingPage: React.FC = (): JSX.Element => {
  return (
    <div className="container">
      <div className="holding-page w-100 align-items-center bg-light">
        <FontAwesomeIcon icon={faHeartbeat} size="2x" />
        <ReactMarkdown source={availabilityExpiredContent} />
      </div>
    </div>
  )
}
