import React, { useMemo } from 'react'
import { Container, Nav } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import {
  CentreUnit,
  isHOC,
  useAuth0,
  ValuationStatus,
} from '../../../../common'

export const CentreUnitPageNotificationMessage: React.FC<{
  centres: CentreUnit[]
}> = ({ centres }): JSX.Element => {
  const { user } = useAuth0()

  const notificationMessage = useMemo<any>(() => {
    if (user && isHOC(user)) {
      const submissionCount = (centres || []).filter(
        (x) => x.valuationStatus === ValuationStatus.SUBMITTED && !x.closed
      ).length
      if (submissionCount > 0) {
        const unitWord = submissionCount ? 'units' : 'unit'
        return {
          icon: faBell,
          message: `You have ${submissionCount} ${unitWord} waiting for your approval`,
          className: 'text-white bg-success',
        }
      }
    }
  }, [user, centres])

  return (
    <>
      {notificationMessage && (
        <Nav
          className={`font-weight-bold py-3 ${notificationMessage.className}`}
        >
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              {notificationMessage.icon && (
                <FontAwesomeIcon
                  icon={notificationMessage.icon}
                  className="mr-3"
                />
              )}
              {notificationMessage.message}
            </span>
          </Container>
        </Nav>
      )}
    </>
  )
}
