import React from 'react'
import { InlineErrorMessageNoBorder, ValuationStatus } from '../../../common'

interface Props {
  valuationStatus: ValuationStatus
  showError?: boolean
}

export const DownloadModalContent: React.FC<Props> = ({
  valuationStatus,
  showError,
}) => {
  if (
    [
      ValuationStatus.NOT_STARTED,
      ValuationStatus.INCOMPLETE,
      ValuationStatus.COMPLETE,
    ].includes(valuationStatus)
  ) {
    return (
      <>
        <p>
          The template is a CSV file containing all the entry information for
          this unit. It will also include any teacher assessed grades you’ve
          already entered into the system.
        </p>
        <p>
          Once you have downloaded and filled in the template, you can return to
          this page to upload it.
        </p>
      </>
    )
  }

  if (valuationStatus === ValuationStatus.SUBMITTED) {
    return (
      <p>
        This template is a CSV file that contains all the data you have
        provided. It is awaiting approval by your Head of Centre and cannot
        currently be edited. <u>If you require assistance please contact us.</u>
      </p>
    )
  }

  if (valuationStatus === ValuationStatus.APPROVED) {
    return (
      <p>
        This template is a CSV file that contains all the data you have
        provided. It has been approved and submitted by your Head of Centre and
        cannot be edited. <u>If you require assistance please contact us.</u>
      </p>
    )
  }

  if (showError) {
    return (
      <InlineErrorMessageNoBorder title="Download failed, please try again and if the problem persists contact your system administrator" />
    )
  }

  return (
    <InlineErrorMessageNoBorder title="Unknown valuation status, please check your data again and if the problem persists contact your system administrator" />
  )
}
