import React from 'react'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from 'reactstrap'

interface Props {
  className?: string
  color?: string
  download: () => void
  pending: boolean
  dataTestid?: string
  inline?: boolean
}

export const DownloadButton: React.FC<Props> = ({
  children,
  className,
  color = 'success',
  download,
  pending,
  dataTestid,
  inline = false,
}): JSX.Element => (
  <Button
    color={color}
    className={className}
    disabled={pending}
    onClick={download}
    data-testid={dataTestid}
  >
    {pending ? (
      <Spinner size="sm" className="mr-2" />
    ) : (
      <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
    )}
    {inline ? <span>{children}</span> : <div>{children}</div>}
  </Button>
)
