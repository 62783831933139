import axios, { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useAsyncTaskAxios } from 'react-hooks-async'

export const useExtendDeadline = (
  centreId: string,
  unitId: string,
  deadline: Date | null
) => {
  const requestConfig = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}/deadline`,
      method: 'PUT',
      params: {
        deadline: deadline?.getTime(),
      },
    }
  }, [centreId, unitId, deadline])

  const request = useAsyncTaskAxios<AxiosResponse>(axios, requestConfig)

  const startRequest = () => {
    deadline && request.start()
  }

  return {
    start: startRequest,
    result: request.result,
    error: request.error,
    inProgress: request.started && request.pending,
    executed: request.result && !request.error,
  }
}
