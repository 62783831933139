import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons'
import { CentreUnit } from '../../../../common'
import { ManageUploadModal } from '../../../upload'

interface Props {
  unit: CentreUnit
  onUploadComplete: (uploadedWithErr: boolean) => void
}

export const UploadTemplateButton: React.FC<Props> = ({
  unit,
  onUploadComplete,
}) => {
  const [uploadModalShow, setUploadModalShow] = useState(false)
  return (
    <>
      <Button
        color="e4"
        className="mr-3 text-secondary text-center"
        onClick={(e) => {
          e.preventDefault()
          setUploadModalShow(true)
        }}
        data-testid="unit-page-csv-template-upload-button"
      >
        <FontAwesomeIcon icon={faArrowToTop} fixedWidth />
        <div>Upload Template</div>
      </Button>
      {unit && (
        <ManageUploadModal
          onUploadComplete={(uploadedWithErr) => {
            setUploadModalShow(false)
            onUploadComplete(uploadedWithErr)
          }}
          onClose={() => {
            setUploadModalShow(false)
          }}
          {...unit}
          isOpen={uploadModalShow}
        />
      )}
    </>
  )
}
