import React from 'react'
import { Modal, ModalProps } from 'reactstrap'

export const ProjectModal: React.FC<ModalProps> = ({
  children,
  className,
  contentClassName,
  dataTestid = 'project-modal',
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      role="alertdialog"
      className={`${className} border-0 rounded`}
      contentClassName={`${contentClassName} bg-transparent rounded border-0`}
      data-testid={dataTestid}
    >
      {children}
    </Modal>
  )
}
