import React, { useContext, useMemo, useState } from 'react'
import { BackButtonData } from './common';

interface IBackButtonContext {
  data: BackButtonData | null
  setData: (newValue: BackButtonData | null) => void
}
const UseBackButtonContext = React.createContext<IBackButtonContext>({
  data: null,
  setData: () => null
})

export const useBackButton = () => useContext(UseBackButtonContext)

export const BackButtonProvider: React.FC = ({ children }) => {
  const [backButtonData, setBackButtonData] = useState<BackButtonData | null>(null)

  const returnValue = useMemo(() => ({
    data: backButtonData,
    setData: setBackButtonData
  }), [backButtonData])

  return (
    <UseBackButtonContext.Provider value={returnValue}>
      {children}
    </UseBackButtonContext.Provider>
  )
}
