import React from 'react'
import { TextAlign } from '../../enum'
import { Col, Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'

interface Props {
  title: string
  toggle: VoidFunction
  textAlign: TextAlign
  upDownActive: string
  sortable: boolean
  size?: number
}

export const SortCell: React.FC<Props> = ({
  title,
  toggle,
  textAlign,
  upDownActive,
  sortable,
  size,
}): JSX.Element => {
  const inactiveColor = {
    color: '#d4d4d4',
  }

  const iconProps = {
    up: upDownActive === 'up' ? {} : inactiveColor,
    down: upDownActive === 'down' ? {} : inactiveColor,
  }

  return (
    <Col
      className={`text-${textAlign}`}
      md={size}
      data-testid="candidates-sorter"
    >
      <Label
        onClick={toggle}
        className={`sorter-label noselect text-primary bg-none px-0 my-0 ${
          sortable ? 'sortable' : ''
        }`}
      >
        {title}

        {sortable && (
          <span>
            <FontAwesomeIcon
              icon={faSortUp}
              {...iconProps.up}
              className={`sort-up ml-2`}
            />
            <FontAwesomeIcon
              icon={faSortDown}
              {...iconProps.down}
              className={`ml-2`}
            />
          </span>
        )}
      </Label>
    </Col>
  )
}
