import React from 'react'
import { ROLES, withCentreId, withExpiration } from '../../common'
import { ManagementPage } from './management-page'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'ocr') {
  INFO_DESCRIPTION = (
    <>
      The <b>Teacher</b> user role can enter and upload teacher assessed grades
      and send them to the Head of Centre for approval. This role can also
      download additional assessment materials.
      <br />
      Please enter your staff members' details. Users will receive a
      confirmation email with a verification link. Users must click the link to
      gain access to the system. Users will not be able to enter or upload
      teacher assessed grades or download additional assessment materials until
      they have done this.
    </>
  )
}

export const ManageTeachersPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Add new Teachers"
    description={INFO_DESCRIPTION}
    buttonText="Add teacher"
    emptyListMessage="There are currently no teachers at this centre"
    role={ROLES.Teacher}
    currentUsersLabel="Current teachers"
  />
)

export const ManageTeachersPageWithRoute = withCentreId(ManageTeachersPage)
export const ManageTeachersPageWithExpiration = withExpiration(
  ManageTeachersPage
)
export const ManageTeachersPageWithRouteAndExpiration = withExpiration(
  ManageTeachersPageWithRoute
)
