import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useMemo } from 'react'
import { Button } from 'reactstrap'
import './page-pagination.scss'

interface Props {
  currentPage: number
  pagesAmount: number
  onPageChange: (page: number) => void
}

export const PagePagination: FC<Props> = ({
  pagesAmount,
  onPageChange,
  currentPage,
}) => {
  const maxButtonsBeforeCurrentPage = 5
  const maxButtonsAfterCurrentPage = 4

  const getSelectPageButton = (page: number) => (
    <Button
      key={page}
      className="select-page-button pagination-item"
      onClick={() => onPageChange(page)}
    >
      {page}
    </Button>
  )

  const buttonsBeforeCurrentPage = useMemo(() => {
    if (currentPage < maxButtonsBeforeCurrentPage) {
      const arr = []
      for (let page = 1; page < currentPage; page++) {
        arr.push(getSelectPageButton(page))
      }
      return <>{arr}</>
    }
    return (
      <>
        {getSelectPageButton(1)}
        <div className="spread-container pagination-item">...</div>
        {getSelectPageButton(currentPage - 1)}
      </>
    )
  }, [currentPage, pagesAmount])

  const buttonsAfterCurrentPage = useMemo(() => {
    if (currentPage > pagesAmount - maxButtonsAfterCurrentPage) {
      const arr = []
      for (let page = currentPage + 1; page <= pagesAmount; page++) {
        arr.push(getSelectPageButton(page))
      }
      return <>{arr}</>
    }
    return (
      <>
        {getSelectPageButton(currentPage + 1)}
        <div className="spread-container pagination-item">...</div>
        {getSelectPageButton(pagesAmount)}
      </>
    )
  }, [currentPage, pagesAmount])

  if (pagesAmount === 1) {
    return <></>
  }

  return (
    <div className="pagination">
      <Button
        className="decrement-button pagination-item"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
      </Button>
      {buttonsBeforeCurrentPage}
      <Button
        className="current-page pagination-item"
        onClick={() => onPageChange(currentPage - 1)}
        color="primary"
        disabled
      >
        {currentPage}
      </Button>
      {buttonsAfterCurrentPage}
      <Button
        className="increment-button pagination-item"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pagesAmount}
      >
        <FontAwesomeIcon icon={faChevronRight} fixedWidth />
      </Button>
    </div>
  )
}
