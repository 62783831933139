import axios, { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { FilePathService } from '../services'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { SpecificationFiles } from '../types'

export const useSpecificationFiles = (
  qualification: string,
  specification: string,
  unitCode: string
) => {
  const getFileToSpecificationsUrl = useCallback(
    (search: string, page: number) => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/${FilePathService.encode(specification)}/${FilePathService.encode(
        unitCode
      )}/search`,
      params: {
        limit: 10000,
        offset: 0,
        text: search.trim(),
      },
    }),
    [qualification, specification, unitCode]
  )

  const [fileUrl, setFileUrl] = useState(getFileToSpecificationsUrl('', 1))

  const getFiles = (search: string, page: number = 0) => {
    setFileUrl(getFileToSpecificationsUrl(search, 1))
  }

  const getFilesToSpecification = useAsyncTaskAxios<
    AxiosResponse<SpecificationFiles>
  >(axios, fileUrl)

  useEffect(() => {
    getFilesToSpecification.start()
  }, [fileUrl])

  return {
    getFiles,
    result: getFilesToSpecification.result?.data,
    inProgress:
      getFilesToSpecification.started && getFilesToSpecification.pending,
  }
}
