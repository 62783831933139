import React, { useEffect, useMemo, useState } from 'react'
import { ICandidateUpdateExtended } from './constants'
import { CentreUnit, UnitCandidate, usePatchCandidate } from '../../common'
import { CandidatesList } from './candidates-list'
import { ChangingGradeModal } from './components'
import { IChangeRequest } from './types'

interface Props {
  gradeFilter: string
  candidates: UnitCandidate[]
  lastUpdated: number
  showErrors: boolean
  viewOnly: boolean
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (status: boolean) => void
  unit: CentreUnit
}

export const CandidatesListContainer: React.FC<Props> = ({
  gradeFilter,
  showErrors,
  toggleShowErrors,
  candidates,
  viewOnly,
  approvalDisabled,
  unit,
}): JSX.Element => {
  const [cachedCandidates, updateCachedCandidates] = useState<UnitCandidate[]>(
    candidates
  )
  const [changeRequest, setChangeRequest] = useState<IChangeRequest | null>(
    null
  )
  const [showModal, setShowModal] = useState(false)

  const {
    patchCandidate,
    candidatesPatchingProgress,
    clearPatchingErrors,
    lastUpdate,
  } = usePatchCandidate(unit, cachedCandidates, updateCachedCandidates)

  useEffect(() => {
    updateCachedCandidates(candidates)
  }, [candidates])

  const onPatchRequest = useMemo(
    () => (update: ICandidateUpdateExtended) => {
      if (viewOnly) return
      const candidate = cachedCandidates.find((c) => c.id === update.id)
      if (!candidate) return

      if (candidate.grade && candidate.grade !== update.grade) {
        handleChangeGrade(candidate, update)
      } else {
        patchCandidate(update)
      }
    },
    [cachedCandidates, setChangeRequest, setShowModal, patchCandidate, viewOnly]
  )

  const handleChangeGrade = (
    candidate: UnitCandidate,
    update: ICandidateUpdateExtended
  ) => {
    setChangeRequest(null)
    patchCandidate(update)
  }

  const isShowSetXGradeButton = useMemo(() => {
    return cachedCandidates.some((candidate) => !candidate.grade)
  }, [cachedCandidates])

  useEffect(() => {
    clearPatchingErrors()
  }, [clearPatchingErrors, gradeFilter])

  const onSuccessBulkXGrade = () => {
    updateCachedCandidates((cachedCandidates) =>
      cachedCandidates.map((candidate) =>
        candidate.grade ? candidate : { ...candidate, grade: 'X' }
      )
    )
  }

  const changeGrade = () => {
    if (changeRequest) {
      patchCandidate(changeRequest.update)
      setShowModal(false)
    }
    setChangeRequest(null)
  }

  return (
    <div>
      <CandidatesList
        approvalDisabled={approvalDisabled}
        gradeFilter={gradeFilter}
        showErrors={showErrors}
        toggleShowErrors={toggleShowErrors}
        candidates={cachedCandidates}
        candidatesPatchingProgress={candidatesPatchingProgress}
        clearPatchingErrors={clearPatchingErrors}
        patchCandidate={onPatchRequest}
        lastUpdated={lastUpdate}
        upToDate={
          Object.values(candidatesPatchingProgress).filter(
            (x) => x === 'pending'
          ).length === 0
        }
        viewOnly={viewOnly}
        unit={unit}
        isShowSetXGradeButton={isShowSetXGradeButton}
        onSuccessBulkXGrade={onSuccessBulkXGrade}
      />
      {changeRequest && (
        <ChangingGradeModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          changeRequest={changeRequest}
          changeGrade={changeGrade}
        />
      )}
    </div>
  )
}
