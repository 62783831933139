import { useMemo } from 'react'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { PreSignedUploadUrl } from '../types'
import { UnitStatusChangeReason } from '../enum'

export const useReopenUnit = (
  centreId: string,
  unitId: string,
  changeReason?: UnitStatusChangeReason
) => {
  const requestConfig = useMemo<AxiosRequestConfig>(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}/reopen`,
      params: {
        changeReason,
      },
      method: 'PATCH',
    }),
    [centreId, unitId, changeReason]
  )

  const request = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
    axios,
    requestConfig
  )

  const startRequest = () => {
    changeReason && request.start()
  }

  return {
    start: startRequest,
    inProgress: request.started && request.pending,
    result: request.result,
    status: request.result?.status,
    error: request.error,
  }
}
