import React, { useMemo, useState } from 'react'
import { Col, Row, Spinner, Button } from 'reactstrap'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  CentreUnit,
  escapeSlashes,
  formatDate,
  getSubTitle,
  SearchBar,
  UnitCandidate,
} from '../../../common'
import { DownloadTemplateButton } from '../../download-template-button'
import { BulkSetXGrade } from '../../bulk-set-x-grade'

const UpdatingLabel: React.FC<{ updating: boolean }> = ({
  updating,
}): JSX.Element => (
  <Button color="primary" disabled>
    {updating ? (
      <Spinner size="sm" className="mr-2" />
    ) : (
      <FontAwesomeIcon icon={faCheck} className="mr-2" />
    )}
    {updating ? 'Saving' : 'Auto-saved'}
  </Button>
)

interface Props {
  unit: CentreUnit
  lastUpdated: number
  upToDate: boolean
  viewOnly: boolean
  filterChanged: (val: string) => void
  checkForErrorsClick: () => void
  approvalDisabled: boolean
  isShowSetXGradeButton: boolean
  onSuccessBulkXGrade: () => void
  candidates: UnitCandidate[]
}

export const CandidatesSearchLine: React.FC<Props> = ({
  unit,
  approvalDisabled,
  lastUpdated,
  checkForErrorsClick,
  viewOnly,
  upToDate,
  filterChanged,
  isShowSetXGradeButton,
  onSuccessBulkXGrade,
  candidates,
}): JSX.Element => {
  const lastUpdatedString = useMemo(
    () => escapeSlashes(formatDate(new Date(lastUpdated), true, true), ' / '),
    [lastUpdated]
  )

  const [searchInput, setSearchInput] = useState('')

  const setInput = (val: string = '') => {
    setSearchInput(val)
    filterChanged(val.trim())
  }

  const title = useMemo(() => unit.unitName || unit.unitId, [unit])
  const subTitle = useMemo(() => getSubTitle(unit.unitName, unit.unitId), [
    unit,
  ])

  return (
    <Row className="search-line mb-5 align-items-center">
      <Col md={10}>
        <SearchBar
          val={searchInput}
          placeholder="Search for a candidate"
          filterChanged={(newVal) => setInput(newVal)}
          disableSearch={searchInput.length < 5}
          dataTestid="candidates-search-bar"
        />
      </Col>
      {!viewOnly && (
        <>
          <Col className="text-right last-updated">
            {!!lastUpdated && (
              <>
                <div>Last updated on</div>
                <div>{lastUpdatedString}</div>
              </>
            )}
          </Col>
          <Col className="text-right" xs="auto">
            <UpdatingLabel updating={!upToDate} />
          </Col>
          <Col className="text-right" xs="auto">
            {isShowSetXGradeButton && (
              <BulkSetXGrade
                disabled={!upToDate}
                header={title}
                subheader={subTitle}
                ancillery={`${candidates.length} candidates`}
                candidates={candidates}
                onSuccess={onSuccessBulkXGrade}
              />
            )}
            <Button
              color="success"
              onClick={checkForErrorsClick}
              data-testid="candidates-search-line-check-errors-button"
            >
              {approvalDisabled ? (
                'Check for errors'
              ) : (
                <div>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  No errors found
                </div>
              )}
            </Button>
          </Col>
        </>
      )}
      {viewOnly && (
        <Col className="text-right">
          <DownloadTemplateButton unit={unit} />
        </Col>
      )}
    </Row>
  )
}
