import axios, { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { FilePathService } from '../services'
import { useAxios } from 'react-hooks-async'
import { SpecificationsDTO } from '../types'

export const useSpecificationUnits = (
  qualification: string,
  specification: string | null = null
) => {
  const requestParams = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/${
        specification
          ? `${FilePathService.encode(specification)}/unitCodes`
          : 'specifications'
      }`,
    }),
    [qualification, specification]
  )

  const task = useAxios<AxiosResponse<SpecificationsDTO>>(axios, requestParams)

  return {
    result: task.result?.data.items,
    inProgress: task.started && task.pending,
  }
}
