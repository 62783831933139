import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import {
  Loading,
  escapeSlashes,
  ResultsCard,
  useSpecificationUnits,
} from '../../common'

import './unit-code.scss'

interface Props {
  qualification: string
  specification: string
}

export const UnitCodes: React.FC<Props> = ({
  qualification,
  specification,
}) => {
  const history = useHistory()

  const navigate = useCallback(
    (unitCode: string) =>
      history.push(
        `/aam/${qualification}/${escapeSlashes(specification)}/${escapeSlashes(
          unitCode
        )}`
      ),
    [history, qualification, specification]
  )

  const { result, inProgress } = useSpecificationUnits(
    qualification,
    specification
  )

  if (!result || inProgress) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <Container className="unit-code-container container">
      <Row className="justify-content-center mx-auto">
        <Col>
          <h5 className="title">{specification}</h5>
          <p className="unit-code-info">
            Click on a button to view the additional assessment material for
            that unit/component.
          </p>
        </Col>
      </Row>
      <div className="cards-container">
        {result.map((unit, index) => (
          <div className="unit-card" key={unit}>
            <ResultsCard
              title={unit}
              buttonText="See files"
              onClick={() => navigate(unit)}
              dataTestIdSuffix={index}
            />
          </div>
        ))}
      </div>
    </Container>
  )
}
