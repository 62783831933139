import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons'
import { Container, Row, Col, Button } from 'reactstrap'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import {
  faUsers,
  faBookReader,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { isAdmin, useAuth0 } from '../../index'
import './not-available.scss'

export const NotAvailable: React.FC<{
  availableTo?: Date
}> = ({ availableTo }): JSX.Element => {
  const { user } = useAuth0()
  return (
    <Container className="not-available">
      <FontAwesomeIcon size="2x" className="fa-icon" icon={faClock} />
      <h5 className="mt-4 font-weight-bold text-center">Welcome</h5>
      <p>
        The Grade Submission System will not be available for entering grades{' '}
        <u>until {availableTo ? format(availableTo, 'MMMM do yyyy') : ''} </u>
      </p>
      {user && !isAdmin(user) && (
        <p className="mt-4">
          Please come back to the system at this date, thank you.
        </p>
      )}
      {user && isAdmin(user) && (
        <>
          <p className="mt-4">
            You can configure users and Sign Off authority now.
          </p>
          <Row className="mt-5">
            <Col className="text-right">
              <Button
                tag={Link}
                style={{ minWidth: '187px' }}
                to="/manage/aam"
                color="lightButNotTooLight font-weight-bold text-secondary"
              >
                <FontAwesomeIcon icon={faBookReader} />
                <div>Manage Additional Assessment Material</div>
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                tag={Link}
                style={{ minWidth: '187px' }}
                to="/manage/teachers"
                color="lightButNotTooLight font-weight-bold text-secondary"
              >
                <FontAwesomeIcon icon={faUsers} />
                <div>Manage Teachers</div>
              </Button>
            </Col>
            <Col>
              <Button
                tag={Link}
                style={{ minWidth: '187px' }}
                to="/manage/signOff"
                color="lightButNotTooLight font-weight-bold text-secondary"
              >
                <FontAwesomeIcon icon={faUserShield} />
                <div>Set up Sign Off Authority</div>
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
