import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faShieldVirus,
} from '@fortawesome/pro-solid-svg-icons'
import { countBy, ValidationError } from '../../../common'
import getTextFromError from '../../../common/services/errorDictionary'

export const UploadFailed: React.FC<{
  fileName: string
  errors?: ValidationError[]
  expectedCandidates: number
  updatedCandidatesCount: number
}> = ({ fileName, errors, expectedCandidates, updatedCandidatesCount }) => {
  const errMapped = useMemo(() => {
    return countBy(errors, (err) => err.errorCode)
  }, [errors])

  return (
    <div className="modal-margin">
      {(errors || []).find(
        (x) => x.errorMessage === 'Uploaded file was infected'
      ) ? (
        <>
          <p className="font-larger text-danger list-inline-item font-weight-bold">
            The system has detected virus in your template
          </p>
          <FontAwesomeIcon
            icon={faShieldVirus}
            className="text-danger fa-2x"
            fixedWidth
          />
          <p>Please re-check / clean your file and upload again.</p>
        </>
      ) : (
        <>
          <p className="text-danger list-inline-item font-weight-bold">
            Template uploaded with errors
          </p>
          <p className="text-danger list-inline-item">{`(${fileName})`}</p>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-danger small"
            fixedWidth
          />
          <p className="font-smaller w-75">
            All valid data will be imported, but the errors below will not be
            imported in to the system.
          </p>
          {errors && errors.length > 0 && (
            <div className="border rounded border-danger p-4">
              {Object.keys(errMapped).map((e) => (
                <li key={e} className="font-weight-bold text-danger">
                  {getTextFromError(e, errMapped[e])}
                </li>
              ))}
            </div>
          )}
          {errors && errors.length > 0 && (
            <div className="mt-2 font-weight-bold">{`${updatedCandidatesCount} / ${expectedCandidates} candidates uploaded successfully`}</div>
          )}
        </>
      )}
    </div>
  )
}
