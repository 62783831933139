import React, { FC, useMemo } from 'react'
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from 'reactstrap'
import {
  FileImageService,
  FilePathService,
  SpecificationFile,
  usePresignedDownload,
} from '../../../common'

interface Props {
  file: SpecificationFile
  index: number
}

export const FileRow: FC<Props> = ({ file, index }) => {
  const requestParams = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/aam/downloadUrl`,
      params: {
        path: FilePathService.encode(file.absolutePath),
        bucket: FilePathService.encode(file.bucket),
      },
      fileName: file.fileName,
    }),
    [file]
  )

  const { download, pending } = usePresignedDownload(requestParams)

  return (
    <div className="file-row">
      <FontAwesomeIcon
        className={'file-image'}
        icon={FileImageService.getFileImageByFileType(file.fileType)}
      />
      <span className="file-name" data-testid="file-name">
        {file.fileName}
      </span>
      <Button
        className="download-button"
        color="primary"
        onClick={download}
        data-testid={`download-button-${index}`}
      >
        {pending ? (
          <Spinner className="mr-2" size="sm" />
        ) : (
          <FontAwesomeIcon
            className="download-icon"
            icon={faCloudDownloadAlt}
          />
        )}
        Download
      </Button>
    </div>
  )
}
