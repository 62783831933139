import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Button, Col, Row } from 'reactstrap'
import {
  formatDate,
  ProjectModal,
  RsModalHeader,
  RsModalBody,
  Checkbox,
} from '../../common'
import { CentreUnit } from '../../common'

import './hoc-approve-modal.scss'

import declarationContent from './declaration-content.md'

interface Props {
  unit: CentreUnit
  isOpen: boolean
  close: () => void
  approve: () => void
}

export const HOCApproveModal: React.FC<Props> = ({
  unit,
  isOpen,
  close,
  approve,
}) => {
  const [confirmation, updateConfirmation] = useState(false)

  return (
    <ProjectModal
      className="hoc-approval-modal"
      centered
      isOpen={isOpen}
      dataTestid="hoc-approval-modal"
    >
      <RsModalHeader toggle={close} className="bg-white px-5 pt-5 pb-25">
        <h6
          className="mb-0 font-weight-bold text-success"
          style={{ fontSize: '22px' }}
        >{`${unit.unitName}`}</h6>
        <h5 className="text-secondary font-weight-semi-bold">
          <span>{`${unit.unitId}`}</span>
          <span className="ml-3 mr-3">|</span>
          <span>{`${unit.totalCandidates} candidates`}</span>
        </h5>
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <Row className="mb-4">
          <Col>
            <div className="markdown-content">
              <ReactMarkdown source={declarationContent} />
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col className="d-inline-flex declaration-confirmation">
            <Checkbox
              value={confirmation}
              toggle={() => updateConfirmation(!confirmation)}
            />
            <h6 className="font-weight-bold m-0">
              I confirm that the data we submit is final and correct. I agree to
              the Head of Centre declaration on behalf of my centre.
            </h6>
          </Col>
        </Row>
        <Row className="mt-4 ml-0 justify-content-between">
          <Col>
            <h6 className="font-weight-bold">Date authorised</h6>
            <h6 className="font-weight-bold text-secondary">
              {formatDate(new Date(), true, true)}
            </h6>
          </Col>
          <Col className="d-inline-flex justify-content-end align-items-center">
            <Button
              onClick={approve}
              className="bg-success"
              disabled={!confirmation}
              data-testid="hoc-approval-modal-submit-btn"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </RsModalBody>
    </ProjectModal>
  )
}
