import React from 'react'
import {
  faBookReader,
  faUsers,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'
import { isCAAdmin, NavTab, ROLES, TabList, useAuth0 } from '../../common'

interface Props {
  centreId: string
  activeRole: string
}

export const NavTabs: React.FC<Props> = ({
  activeRole,
  centreId,
}): JSX.Element => {
  const { user } = useAuth0()
  return (
    <TabList className="mb-6">
      <NavTab
        to={
          user && isCAAdmin(user)
            ? `/centres/${centreId}/manage/aam`
            : '/manage/aam'
        }
        label="Manage Additional Assessment Material"
        icon={faBookReader}
        active={activeRole === ROLES.AAM}
      />
      <NavTab
        to={
          user && isCAAdmin(user)
            ? `/centres/${centreId}/manage/teachers`
            : '/manage/teachers'
        }
        label="Manage Teachers"
        icon={faUsers}
        active={activeRole === ROLES.Teacher}
      />
      <NavTab
        to={
          user && isCAAdmin(user)
            ? `/centres/${centreId}/manage/signoff`
            : `/manage/signoff`
        }
        label="Manage Head of Centre"
        icon={faUserShield}
        active={activeRole === ROLES.HOC}
      />
    </TabList>
  )
}
