import React, { FC, useMemo, useState } from 'react'
import { NoSearchResults } from './no-search-results'
import { Pagination } from './pagination'
import { FileRow } from './file-row'
import { Container } from 'reactstrap'
import { Loading, SearchBar, useSpecificationFiles } from '../../common'

import './specification-files.scss'

interface Props {
  qualification: string
  specification: string
  unitCode: string
}

export const SpecificationFiles: FC<Props> = ({
  qualification,
  specification,
  unitCode,
}) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)

  const { getFiles, result, inProgress } = useSpecificationFiles(
    qualification,
    specification,
    unitCode
  )
  const calculateOffset = (page: number) => limit * (page - 1)

  const paginatedFiles = useMemo(() => {
    const offset = calculateOffset(currentPage)
    return result?.items.slice(offset, offset + limit)
  }, [currentPage, limit, result])

  const handleSearch = () => {
    getFiles(searchInput, 1)
    setSearchTerm(searchInput)
    setCurrentPage(1)
  }

  const handleFilterClear = () => {
    getFiles('', currentPage)
    setSearchInput('')
  }

  const pageAmount = useMemo(() => Math.ceil((result?.size || 0) / limit), [
    limit,
    result,
  ])

  if (!result || inProgress) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <div>
      <div className="specification-files container">
        <strong className="header">{unitCode}</strong>
        <div className="search-files">
          <SearchBar
            val={searchInput}
            placeholder="Search for a topic, term or reference"
            filterChanged={setSearchInput}
            onFilterClear={handleFilterClear}
            onClick={handleSearch}
            disableSearch={false}
            dataTestid="specifications-search-bar"
          />
        </div>

        {result.items.length === 0 ? (
          <div className="files-list-container">
            <NoSearchResults searchTerm={searchTerm} />
          </div>
        ) : (
          <div>
            <div className="files-list-container">
              <div className="search-info">
                <div>
                  For each unit/component there will a mapping grid which
                  contains important information.
                </div>
                <div>
                  For each question set (QS) there will be a number of files.
                  These may include the questions and mark scheme (QMS), maps
                  (MAP), and audio tracks (Track).
                </div>
                <div className="amount-files">
                  Number of files available for download: {result.size}
                </div>
              </div>

              {paginatedFiles?.map((file, index) => (
                <FileRow file={file} key={file.fileName} index={index} />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageAmount={pageAmount}
              limit={limit}
              setLimit={setLimit}
            />
          </div>
        )}
      </div>
    </div>
  )
}
