import {
  LetterWithSpecifications,
  ManagedUser,
  User,
  ValuationStatus,
} from '../types'
import { AddUserEvents, SystemAvailabilityEnum } from '../enum'
import { BLOCK_HEIGHT, PADDING, ROLES, ROW_HEIGHT } from '../constants'

export const isSuperUser = (user: User) => user.roles.includes(ROLES.QA_Super)

export const isCAAdmin = (user: User, hardCheck?: boolean) =>
  user.roles.includes(ROLES.CA_Admin) || (!hardCheck && isSuperUser(user))
export const isAdmin = (user: User) =>
  user.roles.includes(ROLES.Admin) || isSuperUser(user)
export const isHOC = (user: User) =>
  user.roles.includes(ROLES.HOC) || isSuperUser(user)
export const isTeacher = (user: User) =>
  user.roles.includes(ROLES.Teacher) || isSuperUser(user)
export const isAAM = (user: User) =>
  user.roles.includes(ROLES.AAM) || isSuperUser(user)

export const unitInProgress = (status: any) =>
  [
    ValuationStatus.NOT_STARTED,
    ValuationStatus.INCOMPLETE,
    ValuationStatus.COMPLETE,
  ].includes(status)

export const calculateAddUserEvent = (
  user: ManagedUser,
  role: string,
  centreId: string
): AddUserEvents | null => {
  const centresLength = user.centres?.length || 0
  if (centresLength === 0) {
    return null
  }

  const containsCurrentCentre = user.centres?.includes(centreId)
  if (containsCurrentCentre && user.role === role) {
    return AddUserEvents.SAME_CENTRE_SAME_ROLE
  }

  if (centresLength === 1 && containsCurrentCentre && user.role !== role) {
    return AddUserEvents.SAME_CENTRE_NEW_ROLE
  }

  if (centresLength > 0 && user.role === role) {
    return AddUserEvents.MULTIPLE_CENTRES_SAME_ROLE
  }

  if (centresLength > 0 && user.role !== role) {
    return AddUserEvents.MULTIPLE_CENTRES_NEW_ROLE
  }

  return null
}

export const isCentreUser = (user: User): boolean => {
  return isTeacher(user) || isHOC(user) || isAdmin(user)
}

export const escapeSlashes = (
  str: string,
  replacement: string = '%2F'
): string => str.replace(/\//g, replacement)

export const compareTwoLowerCaseStrings = (
  valueOne: string = '',
  valueTwo: string = ''
): boolean => valueOne.toLowerCase() === valueTwo.toLowerCase()

export const isFunction = (fn: any) =>
  fn && {}.toString.call(fn) === '[object Function]'

export function countBy<T>(
  objects: T[] | undefined,
  func: (item: T) => string | number | null | undefined
): { [key: string]: number } {
  if (!objects) {
    return {}
  }
  return objects.map(func).reduce(
    (map: { [key: string]: number }, val) =>
      val
        ? {
            ...map,
            [val]: (map[val] || 0) + 1,
          }
        : map,
    {}
  )
}

export const snakeCase = (str: string): string => {
  const matches = str?.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  )
  return matches ? matches.map((x) => x.toLowerCase()).join('_') : str
}

export const parseToDate = (date: string): Date => {
  const dateParts: number[] = date
    .split('/')
    .map((part) => Number.parseInt(part))
  // month is 0-based, that's why we need dataParts[1] - 1
  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
}

export const parseSystemAvailableEnum = (value: any) => {
  return (
    SystemAvailabilityEnum[value as keyof typeof SystemAvailabilityEnum] ||
    SystemAvailabilityEnum.SYSTEM_ERROR
  )
}

export const toLetterWithSpecification = (items: string[]) => {
  const res: LetterWithSpecifications[] = []

  items.forEach((item) => {
    const letter = item.trim().toLocaleUpperCase().charAt(0)
    const specifications = res.find((el) => el.letter === letter)
    if (specifications) {
      specifications.items.push(item)
      return
    }
    res.push({
      letter,
      items: [item],
    })
  })

  res.sort((first, second) => first.letter.localeCompare(second.letter))
  return res
}

export const getColumnSpecificationsHeight = (
  specifications: LetterWithSpecifications[]
) => {
  let heightOfFirstColumn = 0
  const fullHeight = getFullSpecificationsBlockHeight(specifications)
  for (let i = 0; heightOfFirstColumn < fullHeight / 2; i++) {
    heightOfFirstColumn += calculateSpecificationsBlockHeight(
      specifications[i].items.length
    )
  }
  return heightOfFirstColumn
}

const getFullSpecificationsBlockHeight = (
  specifications: LetterWithSpecifications[]
) =>
  specifications.reduce(
    (acc, letterSpecifications) =>
      acc +
      calculateSpecificationsBlockHeight(letterSpecifications.items.length),
    0
  )

const calculateSpecificationsBlockHeight = (rowCount: number) =>
  PADDING + BLOCK_HEIGHT + rowCount * ROW_HEIGHT
