import React, { useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import axios, { AxiosResponse } from 'axios'
import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-solid-svg-icons'
import { RemoveUserModal } from '../../remove-user-modal'
import { useAsyncTaskAxios } from 'react-hooks-async'

interface Props {
  centreId: string
  userId?: string
  name: string
  surname: string
  email: string
  role?: string
  onDelete: (email: string) => void
}

export const DeleteUserButton: React.FC<Props> = ({
  centreId,
  userId,
  name,
  surname,
  email,
  role,
  onDelete,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const deleteUserMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/users/${userId}`,
      method: 'DELETE',
    }
  }, [centreId, userId])

  const deleteUserTask = useAsyncTaskAxios<AxiosResponse<void>>(
    axios,
    deleteUserMemo
  )

  const deleteInProgress = deleteUserTask.started && deleteUserTask.pending

  useEffect(() => {
    if (deleteUserTask.result) {
      const t = setTimeout(() => {
        setShowDeleteModal(false)
        onDelete(email)
      }, 500)
      return () => clearTimeout(t)
    }
  }, [deleteUserTask.result, email, onDelete])

  useEffect(() => {
    if (deleteUserTask.error) {
      ReactGA.exception({
        description: `Failed to delete user - ${userId}`,
        fatal: false,
      })
    }
  }, [deleteUserTask.error, userId])

  return (
    <>
      {deleteUserTask.started && deleteUserTask.pending ? (
        <Spinner size="sm" color="primary" />
      ) : (
        <Button
          color="link"
          className="px-0"
          onClick={() => setShowDeleteModal(true)}
        >
          <FontAwesomeIcon className="pointer mr-2" icon={faTrash} />
          Delete
        </Button>
      )}
      <RemoveUserModal
        name={name}
        surname={surname}
        email={email}
        role={role}
        centreId={centreId}
        isOpen={showDeleteModal}
        setOpen={setShowDeleteModal}
        deleteError={deleteUserTask.error}
        deleteInProgress={deleteInProgress || !!deleteUserTask.result}
        onSubmit={() => {
          ReactGA.event({
            category: 'User',
            action: `Delete user - ${role}`,
            label: userId,
          })
          deleteUserTask.start()
        }}
      />
    </>
  )
}
