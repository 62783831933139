import React, { FC } from 'react'
import history from './common/services/history'
import { Router } from 'react-router-dom'
import { Route } from 'react-router'
import { Container } from 'reactstrap'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useConfig, Loading, AppState, Auth0Provider } from './common'
import { RoutesWithHeader } from './components/routes-with-header'
import { ConfigProvider } from './components/config-provider'

const onRedirectCallback = (appState: AppState): void => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const AppWithConfig: FC = ({ children }): JSX.Element => {
  const config = useConfig()
  if (!config.mainConfigLoaded) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }
  return (
    <Container fluid className="m-0 p-0 pb-3">
      <Auth0Provider
        onRedirectCallback={onRedirectCallback}
        {...config.config.auth0Config!}
      >
        {children}
      </Auth0Provider>
    </Container>
  )
}
const App: FC = (): JSX.Element => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>OCR | Grade Submission System</title>
          <link rel="canonical" href="http://mysite.com/example" />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>
      </HelmetProvider>
      <ConfigProvider>
        <AppWithConfig>
          <Router history={history}>
            <Route component={RoutesWithHeader} />
          </Router>
        </AppWithConfig>
      </ConfigProvider>
    </>
  )
}

export default App
