import React from 'react'
import { Spinner } from 'reactstrap'

export const UploadStarted: React.FC<{ fileName: string }> = ({ fileName }) => {
  return (
    <div className="modal-margin">
      <p className=" list-inline-item font-weight-bold">
        Your template is uploading
      </p>
      <p className="list-inline-item">{`(${fileName})`}</p>
      <Spinner className="list-inline-item" size="sm" color="primary" />
      <p>
        We are uploading your template and checking for viruses. This may take a
        few moments. Once the upload is complete all existing data will be
        replaced.
      </p>
    </div>
  )
}
