import React, { useEffect, useState } from 'react'
import {
  ProjectModal,
  RsModalBody,
  RsModalHeader,
  Title,
  useReopenUnit,
} from '../../../common'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import { UnitStatusChangeReason } from '../../../common'

import './unit-reopen-modal.scss'

interface Props {
  modalOpen: boolean
  centreId: string
  unitId: string
  header: string
  subheader: string
  ancillery?: string
  onSuccess: (reopened: boolean) => void
  onClose: () => void
}

export const UnitReopenModal: React.FC<Props> = ({
  modalOpen,
  centreId,
  unitId,
  header,
  subheader,
  ancillery,
  onSuccess,
  onClose,
}) => {
  const [unitStatusChangeReason, setUnitStatusChangeReason] = useState<
    UnitStatusChangeReason
  >()

  const { start, result, inProgress, status, error } = useReopenUnit(
    centreId,
    unitId,
    unitStatusChangeReason
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    start()
  }

  useEffect(() => {
    if (status === 200 && !modalOpen) {
      onSuccess(!!unitStatusChangeReason)
    }
  }, [onSuccess, status, unitStatusChangeReason, modalOpen])

  return (
    <ProjectModal
      isOpen={modalOpen}
      toggle={() => onClose()}
      centered
      className="unit-reopen-modal"
      dataTestid="unit-reopen-modal"
    >
      <RsModalHeader
        toggle={() => onClose()}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Row className="align-items-center justify-content-between mb-2">
          <Title title={header} subTitle={subheader} ancillery={ancillery} />
        </Row>
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        {!inProgress && !result && (
          <>
            <Row className="align-items-center justify-content-between mb-0">
              <h3 className="font-weight-bold h5 mb-1">
                Change status from Submitted to Complete
              </h3>
            </Row>
            <Row className="align-items-center justify-content-between mb-2">
              <p className="font-larger">
                Please choose the reason for changing the status:
              </p>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Row className="align-items-center justify-content-between mb-4">
                <Col className="m-0 mb-2 p-0">
                  {Object.values(UnitStatusChangeReason).map((reason) => (
                    <FormGroup check key={reason}>
                      <Label check>
                        <Input
                          className="unit-form-status-change-input"
                          checked={unitStatusChangeReason === reason}
                          name={reason}
                          type="radio"
                          value={reason}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => {
                            setUnitStatusChangeReason(reason)
                          }}
                          data-testid={`unit-form-status-change-input-${reason
                            .toLowerCase()
                            .replace(/\s/, '-')}`}
                        />
                        <p className="font-larger mb-0">{reason}</p>
                      </Label>
                    </FormGroup>
                  ))}
                </Col>
              </Row>
              <Row>
                <Button
                  color="primary"
                  disabled={!unitStatusChangeReason}
                  type="submit"
                >
                  Re-open form
                </Button>
              </Row>
            </Form>
          </>
        )}
        {inProgress && !result && (
          <Row className="align-items-center justify-content-center">
            <Spinner size="md" className="m-auto p-auto" />
          </Row>
        )}
        {!inProgress && result && (
          <Row className="align-items-center justify-content-start">
            <Col className="pl-0 text-left">
              <p className="font-larger text-success">
                Unit form status successfully changed to{' '}
                <strong>Complete</strong>!
              </p>
            </Col>
          </Row>
        )}
        {error && (
          <Row className="align-items-center justify-content-start mt-4">
            <Col className="pl-0 mt-1 text-left">
              <p className="font-larger text-danger">
                Failed to update unit status, please try again and if the
                problem persists please contact us.
              </p>
            </Col>
          </Row>
        )}
      </RsModalBody>
    </ProjectModal>
  )
}
