import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Tab } from '../tabs'
import { escapeSlashes } from '../../services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  to: string
  label: string
  icon: IconProp
  active: boolean
}

export const NavTab: React.FC<Props> = ({ to, label, icon, active }) => (
  <Tab autoSize to={to}>
    <div
      data-testid={`tab${escapeSlashes(to, '-')}`}
      className={`${
        active ? 'bg-primary text-white' : 'bg-lightButNotTooLight text-dark'
      } rounded font-weight-bold py-25 px-35 position-relative`}
    >
      <div data-testid="unit-filter" className="text-center">
        <div className="font-larger">{label}</div>
        <div
          className={`${
            active ? '' : 'text-secondary'
          } h5 mb-0 font-weight-bold`}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
    </div>
  </Tab>
)
