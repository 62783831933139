import React from 'react'
import { ROLES, withCentreId, withExpiration } from '../../common'
import { ManagementPage } from './management-page'

const INFO_DESCRIPTION = (
  <>
    Please enter your centre admins’ details. Centre admins will receive a
    confirmation email with a verification link. Users must click the link to
    gain access to the system. Centre admins will not be able to enter or upload{' '}
    <b>teacher assessed grades</b> until they have done this.
  </>
)

export const ManageAdminsPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    centreId={centreId}
    title="Manage Centre Admins"
    description={INFO_DESCRIPTION}
    buttonText="Add centre admin"
    emptyListMessage="There are currently no admins at this centre"
    role={ROLES.Admin}
    currentUsersLabel="Current Centre Admins"
  />
)

export const ManageAdminsPageWithRoute = withCentreId(ManageAdminsPage)
export const ManageAdminsPageWithRouteAndExpiration = withExpiration(
  ManageAdminsPageWithRoute
)
