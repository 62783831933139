import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { isAdmin, isCAAdmin, useAuth0, useAvailability } from '../../common'
import { LandingPage } from '../landing-page'
import { HoldingPage } from '../holding-page'

export const HomePage: React.FC = (): JSX.Element => {
  const { user, currentCentre } = useAuth0()
  const { gradingAvailable } = useAvailability()

  if (user) {
    return <LandingPage />
  }

  if (user && isCAAdmin(user)) {
    return <Redirect to="centres" />
  }

  if (user && currentCentre) {
    if (gradingAvailable) {
      return <Redirect to={`centres/${currentCentre}`} />
    }
    if (isAdmin(user)) {
      return <Redirect to="/manage/teachers" />
    }
    return <HoldingPage />
  }
  return <Container />
}
