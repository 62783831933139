import React, { useEffect, useMemo, useState, useCallback } from 'react'
import useReactRouter from 'use-react-router'
import axios, { AxiosError, AxiosResponse } from 'axios'
import ReactGA from 'react-ga'
import querystring from 'querystring'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isFuture } from 'date-fns'
import { Container, Nav } from 'reactstrap'
import { useAsyncTaskAxios, useAxios } from 'react-hooks-async'
import {
  CentreUnit,
  PathParam,
  UnitCandidate,
  ValuationStatus,
  Loading,
  useAuth0,
  isHOC,
  unitInProgress,
  isCAAdmin,
  isForbidden,
  isBadRequest,
  useConfig,
  NotAvailable,
  SimpleMessage,
  SimpleErrorMessage,
  InlineErrorMessageNoBorder,
} from '../../common'
import { CandidatesListContainer } from '../candidates-list'
import { Redirect } from 'react-router-dom'
import { CAAdminBanner } from '../ca-admin-banner'
import { SubjectPageHeader, SubjectPageNotice } from './components'

export const SubjectPage: React.FC = (): JSX.Element => {
  const { config } = useConfig()
  const { match, location } = useReactRouter<PathParam>()
  const { user } = useAuth0()
  const [showErrors, setShowErrors] = useState(false)
  const [approvalDisabled, setApprovalDisabled] = useState(true)
  const [uploadedWithErr, setUploadedWithErr] = useState(false)
  const [unit, setUnit] = useState<CentreUnit | undefined>()

  const centreId = match.params.id
  const unitId = match.params.unitId

  const gradeFilter = useMemo<string>(() => {
    const qs = querystring.parse(
      location.search.startsWith('?')
        ? location.search.slice(1)
        : location.search
    )

    if (!qs.grade) {
      return 'ALL'
    }

    if (Array.isArray(qs.grade)) {
      return qs.grade[0]
    }

    return qs.grade
  }, [location])

  const [valuationStatus, setValuationStatus] = useState<string>('')
  useEffect(() => {
    setValuationStatus(unit?.valuationStatus || '')
  }, [unit])

  const unitClosed = useMemo(() => unit?.closed === true, [unit])

  const viewOnly = useMemo(
    () =>
      !unitInProgress(unit?.valuationStatus) ||
      (user !== undefined && isCAAdmin(user, true)) ||
      unitClosed,
    [user, unit, unitClosed]
  )

  const getCandidatesMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}/candidates`,
    }),
    [centreId, unitId]
  )
  const getCandidatesTask = useAxios<AxiosResponse<UnitCandidate[]>>(
    axios,
    getCandidatesMemo
  )

  useEffect(() => {
    if (uploadedWithErr) {
      setShowErrors(true)
    }
  }, [getCandidatesTask.result, uploadedWithErr])

  const patchUnitMemo = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}`,
      method: 'patch',
    }),
    [centreId, unitId]
  )
  const patchUnitTask = useAsyncTaskAxios<AxiosResponse<CentreUnit>>(
    axios,
    patchUnitMemo
  )

  const badPatch = useMemo(() => {
    if (!patchUnitTask.error) {
      return false
    }
    return isBadRequest((patchUnitTask.error as AxiosError).response)
  }, [patchUnitTask.error])

  const getCentreUnitMemo = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/units/${unitId}`,
    }
    // badPatch should be kept in the dependency array for approval or rejection fail
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [centreId, unitId, badPatch])

  const getCentreUnitTask = useAxios<AxiosResponse<CentreUnit>>(
    axios,
    getCentreUnitMemo
  )

  const forbidden = useMemo(() => {
    if (!getCentreUnitTask.error) {
      return false
    }
    return isForbidden((getCentreUnitTask.error as AxiosError).response)
  }, [getCentreUnitTask.error])

  useEffect(() => setUnit(getCentreUnitTask.result?.data), [
    getCentreUnitTask.result,
  ])

  const viewOnlyState = useMemo<string>(() => {
    if (user && isHOC(user) && valuationStatus !== ValuationStatus.APPROVED) {
      return 'hoc'
    }
    return valuationStatus
    // patchUnitTask.error should be kept in the dependency array for refreshing students list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuationStatus, user, patchUnitTask.error])

  useEffect(() => {
    if (patchUnitTask.result) {
      setUnit(patchUnitTask.result.data)
    }
  }, [patchUnitTask.result])

  const approvalDisabledCallback = (state: boolean) => {
    setApprovalDisabled(state)
  }

  const onUploadComplete = (uploadedWithErr: boolean) => {
    setUploadedWithErr(uploadedWithErr)
    getCentreUnitTask.start()
    getCandidatesTask.start()
  }

  const isDeadlineForSubmittingGradesPassed = useMemo(
    () =>
      (unitInProgress(unit?.valuationStatus) ||
        unit?.valuationStatus === ValuationStatus.SUBMITTED) &&
      unitClosed,
    [unit, unitClosed]
  )

  const onSendBack = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Reject',
      label: unitId,
    })

    patchUnitTask.start({
      data: {
        valuationStatus: ValuationStatus.COMPLETE,
      },
    })
  }, [patchUnitTask, unitId])

  const approveUnitHandler = useCallback(() => {
    ReactGA.event({
      category: 'Grade Submission',
      action: 'Approve',
      label: unitId,
    })
    patchUnitTask.start({
      data: { valuationStatus: ValuationStatus.APPROVED },
    })
  }, [patchUnitTask])

  if (centreId !== centreId.toUpperCase()) {
    return <Redirect to={`/centres/${centreId.toUpperCase()}/unit/${unitId}`} />
  }
  if (
    user &&
    !isCAAdmin(user) &&
    config.captureAvailableFrom &&
    isFuture(config.captureAvailableFrom)
  ) {
    return <NotAvailable availableTo={config.captureAvailableTo} />
  }

  if (unitId && getCentreUnitTask.result) {
    return (
      <>
        <CAAdminBanner centreId={centreId} linkBack />
        {showErrors && (
          <Nav className="error-nav bg-danger text-white font-weight-bold py-4">
            <Container>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                fixedWidth
                className="mr-3"
              />
              This unit contains errors
            </Container>
          </Nav>
        )}

        {patchUnitTask.error && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <InlineErrorMessageNoBorder
                isBgDanger
                title="Failed to update unit, please try again and if the problem persists please contact us"
              />
            </Container>
          </Nav>
        )}
        {isDeadlineForSubmittingGradesPassed && (
          <Nav className={`text-white font-weight-bold py-3 bg-danger`}>
            <Container>
              <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
              (INCOMPLETE) The deadline for submitting grades{' '}
              <u>has now passed,</u> you can view and download the data.
            </Container>
          </Nav>
        )}
        {viewOnly && !unitInProgress(valuationStatus) && !unit?.closed && (
          <SubjectPageNotice
            unit={unit}
            viewOnlyState={viewOnlyState}
            unitId={unitId}
            inProgress={patchUnitTask.started && patchUnitTask.pending}
            approveUnit={approveUnitHandler}
            startTask={onSendBack}
          />
        )}
        <div
          className={`px-3 mb-5 ${
            viewOnly ? 'bg-lightButNotTooLight py-42' : 'bg-light py-45'
          }`}
        >
          <SubjectPageHeader
            approvalDisabled={approvalDisabled}
            viewOnlyState={viewOnlyState}
            unit={unit}
            viewOnly={viewOnly}
            showButtons={!!getCandidatesTask.result}
            onUploadComplete={onUploadComplete}
            setUnitCallback={setUnit}
          />
        </div>
        {getCandidatesTask.pending && (
          <Container>
            <SimpleMessage
              className="mb-5"
              icon={<Loading className="d-block mx-auto" />}
              title="Retrieving candidates..."
            />
          </Container>
        )}
        {!forbidden && !getCentreUnitTask.error && getCandidatesTask.error && (
          <Container>
            <SimpleErrorMessage
              title={`Failed to load candidates, please refresh and if the problem persists contact your system administrator`}
              allowPageRefresh
            />
          </Container>
        )}
        {unit && getCandidatesTask.result && (
          <>
            <CandidatesListContainer
              approvalDisabled={approvalDisabledCallback}
              gradeFilter={gradeFilter}
              candidates={getCandidatesTask.result?.data}
              showErrors={showErrors}
              toggleShowErrors={setShowErrors}
              lastUpdated={Number(unit?.lastUpdated)}
              viewOnly={viewOnly}
              unit={unit}
            />
          </>
        )}
      </>
    )
  }
  return (
    <Container className="mt-5">
      {getCentreUnitTask.pending && (
        <SimpleMessage
          className="mb-5"
          icon={<Loading className="d-block mx-auto" />}
          title="Retrieving unit..."
        />
      )}
      {forbidden && (
        <SimpleErrorMessage title="You do not have permission to submit for this centre" />
      )}
      {!forbidden && getCentreUnitTask.error && (
        <SimpleErrorMessage
          title="Failed to load unit, please refresh and if the problem persists contact your system administrator"
          allowPageRefresh
        />
      )}
    </Container>
  )
}
