import React, { useState } from 'react'
import { Button, Col } from 'reactstrap'
import { UnitReopenModal } from './unit-reopen-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  btnText: string
  header: string
  subheader?: string
  ancillery?: string
  centreId: string
  unitId: string
  gradedCandidates: number
  totalCandidates: number
  unitReopened: (reopened: boolean) => void
}

export const UnitReopenButton: React.FC<Props> = ({
  btnText,
  header,
  subheader = '',
  ancillery,
  centreId,
  unitId,
  unitReopened,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <Col xs="auto" className="unit-reopen-button text-right pl-3">
      <Button
        color="success"
        className="text-uppercase"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setModalOpen(true)
        }}
        data-testid={`unit-progress-reopen-button-${unitId}`}
      >
        <FontAwesomeIcon icon={faPen} className="mr-2 font-normal" />
        <span className="font-normal">{btnText}</span>
      </Button>

      <UnitReopenModal
        modalOpen={modalOpen}
        centreId={centreId}
        unitId={unitId}
        header={header}
        subheader={subheader}
        ancillery={ancillery}
        onSuccess={unitReopened}
        onClose={() => setModalOpen(false)}
      />
    </Col>
  )
}
