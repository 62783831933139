import React from 'react'
import { Button, Container, Nav } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { faCalendarCheck } from '@fortawesome/pro-solid-svg-icons'

interface Props {
  reopenedCount: number
  showReopenedOnly: boolean
  showUnits: () => void
}

export const CentreUnitPageNotice: React.FC<Props> = ({
  reopenedCount,
  showReopenedOnly,
  showUnits,
}): JSX.Element => {
  return (
    <>
      {reopenedCount > 0 && (
        <Nav className="error-nav bg-primary text-white font-weight-bold py-3 top-border">
          <Container className="d-flex align-items-center justify-content-between">
            <span className="d-inline-flex align-items-center">
              <FontAwesomeIcon icon={faBell} className="mr-3" />
              {`You have ${reopenedCount} ${
                reopenedCount > 1 ? 'units' : 'unit'
              } that ${reopenedCount > 1 ? 'are' : 'is'} open for submission`}
            </span>
            {showReopenedOnly && (
              <Container className="w-50 text-right px-0 mx-0">
                <Button
                  color="white"
                  className="text-primary"
                  onClick={showUnits}
                >
                  {`Show ${reopenedCount > 1 ? 'these units' : 'that unit'}`}
                </Button>
              </Container>
            )}
          </Container>
        </Nav>
      )}
      <Nav className="error-nav bg-success text-white font-weight-bold py-4">
        <Container>
          <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="mr-3" />
          <>
            The deadline for submitting some qualifications <u>has passed.</u>
          </>
          <ul className="mt-3 ml-4">
            <li>
              You can only submit data for the qualifications that are still
              open for teacher assessed grades.
            </li>
            <li>
              For others you can view and download. If you have any queries
              please contact us. Thank you.
            </li>
          </ul>
        </Container>
      </Nav>
    </>
  )
}
