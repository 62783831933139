import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { Button } from 'reactstrap'
import { CentreUnit } from '../../common'
import { DownloadModal } from './download-modal'

interface Props {
  unit: CentreUnit
}

export const DownloadTemplateButton: React.FC<Props> = ({ unit }) => {
  const [downloadModalShow, setDownloadModalShow] = useState(false)

  return (
    <>
      <Button
        color="e4"
        className="mr-3 text-center text-secondary"
        onClick={() => {
          setDownloadModalShow(true)
        }}
        data-testid="candidates-search-line-download-template-button"
      >
        <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
        <div>Download Template</div>
      </Button>
      {unit && (
        <DownloadModal
          {...unit}
          onClose={() => setDownloadModalShow(!downloadModalShow)}
          isOpen={downloadModalShow}
        />
      )}
    </>
  )
}
