import React, { useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'

interface Props {
  targetNotMetMessage?: string
}
export const TargetMetNotificaton: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ targetNotMetMessage, className }): JSX.Element => {
  const targetNotMetIconRef = useRef<HTMLSpanElement>(null)
  return (
    <>
      <span
        aria-label="Target not met"
        className={`${className} fa-layers fa-fw  position-absolute font-smaller`}
        ref={targetNotMetIconRef}
        style={{
          right: '-.525rem',
          top: '-0.4rem',
        }}
      >
        <FontAwesomeIcon className="text-danger" icon={faCircle} />
        {targetNotMetMessage && targetNotMetIconRef.current && (
          <UncontrolledTooltip
            placement="top"
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            target={targetNotMetIconRef.current}
          >
            <div className="px-35 py-3 font-weight-bold">
              {targetNotMetMessage}
            </div>
          </UncontrolledTooltip>
        )}
      </span>
    </>
  )
}
