import React from 'react'
import {
  faBookReader,
  faUserCog,
  faUsers,
  faUserShield,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Container, Nav, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  isCAAdmin,
  useAuth0,
  useAvailability,
  withCentreId,
} from '../../common'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

import './ca-admin-banner.scss'

export const CAAdminBanner: React.FC<{
  centreId: string
  linkBack?: boolean
}> = ({ centreId, linkBack }): JSX.Element => {
  const { user } = useAuth0()
  const { gradingAvailable } = useAvailability()

  if (user && isCAAdmin(user)) {
    return (
      <Nav
        className={`ca-admin-banner font-weight-bold py-3 text-white bg-primary`}
      >
        <Container>
          <Row className="align-items-center">
            <Col className="col-back-button">
              {linkBack && gradingAvailable && (
                <Link
                  className="text-white"
                  to={`/centres/${centreId}`}
                  data-testid="ca-admin-banner-link-back"
                >
                  <div className="text-right">
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-3" />
                    Centre {centreId}
                  </div>
                </Link>
              )}

              {(!linkBack || !gradingAvailable) && <>Centre {centreId}</>}
            </Col>
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/teachers`}
                color="white"
                data-testid="ca-admin-banner-manage-teachers-button"
              >
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                Manage Teachers
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/aam`}
                color="white"
                data-testid="ca-admin-banner-manage-aam-button"
              >
                <FontAwesomeIcon icon={faBookReader} className="mr-2" />
                Manage Additional Assessment Material
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                tag={Link}
                to={`/centres/${centreId}/manage/signOff`}
                color="white"
                data-testid="ca-admin-banner-manage-hoc-button"
              >
                <FontAwesomeIcon icon={faUserShield} className="mr-2" />
                Manage Head of Centre
              </Button>
            </Col>
            {user && isCAAdmin(user) && (
              <Col xs="auto">
                <Button
                  tag={Link}
                  to={`/centres/${centreId}/manage/admins`}
                  color="white"
                  data-testid="ca-admin-banner-manage-centre-admins-button"
                >
                  <FontAwesomeIcon icon={faUserCog} className="mr-2" />
                  Manage Centre Admins
                </Button>
              </Col>
            )}
          </Row>
        </Container>
      </Nav>
    )
  } else return <></>
}

export const CAAdminBannerWithRoute = withCentreId(CAAdminBanner)
