export const candidateColumnSizes: { [key: number]: number[] } = {
  2: [6, 18],
  3: [5, 14, 5],
  4: [6, 6, 6, 6],
  5: [6, 4, 4, 5, 5],
}

export interface ICandidateUpdate {
  grade?: string
}

export interface ICandidateUpdateExtended extends ICandidateUpdate {
  id: string
}

export const UNRANKED_GRADE = 'X'
