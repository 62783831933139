import React, { useMemo, useState } from 'react'
import { PagePagination } from '../../../common'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCheck } from '@fortawesome/pro-solid-svg-icons'

import './pagination.scss'

interface Props {
  currentPage: number
  setCurrentPage: (page: number) => void
  pageAmount: number
  limit: number
  setLimit: (limit: number) => void
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  setCurrentPage,
  pageAmount,
  limit,
  setLimit,
}) => {
  const availablePageLimit = [20, 50, 100]

  const [isPageLimitDropdownOpen, setIsPageLimitDropdownOpen] = useState(false)
  const toggleDropDown = () =>
    setIsPageLimitDropdownOpen(
      (isPageLimitDropdownOpen) => !isPageLimitDropdownOpen
    )

  const isShowPerPageContainer = useMemo(
    () => pageAmount !== 1 || limit !== 20,
    [pageAmount, limit]
  )

  const onSetLimit = (pageLimit: number) => {
    setCurrentPage(1)
    setLimit(pageLimit)
  }

  return (
    <div className="pagination-container">
      <PagePagination
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
        pagesAmount={pageAmount}
      />
      {isShowPerPageContainer && (
        <Dropdown
          group
          isOpen={isPageLimitDropdownOpen}
          toggle={toggleDropDown}
        >
          <DropdownToggle className="page-limit-dropdown">
            Show per page:
            <div className="selected-size">
              {limit}
              <FontAwesomeIcon
                className="d-inline-block align-middle dropdown-icon"
                icon={faCaretDown}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu right className="mt-3">
            {availablePageLimit.map((pageLimit) => (
              <DropdownItem
                className={pageLimit === limit ? 'selected-page-size' : ''}
                onClick={() => onSetLimit(pageLimit)}
                key={`page-limit-${pageLimit}`}
              >
                {pageLimit === limit && (
                  <FontAwesomeIcon
                    className="selected-page-size-icon"
                    icon={faCheck}
                  />
                )}
                <div className="page-limit">{pageLimit}</div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}
