import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { CentreUnit } from '../../../../common'
import { ApproveModal } from './approval-modal'

interface Props {
  unit: CentreUnit
  approvalDisabled: boolean
  setUnitCallback: (unit: CentreUnit | undefined) => void
}

export const ApprovalButton: React.FC<Props> = ({
  unit,
  approvalDisabled,
  setUnitCallback,
}: Props) => {
  const [showApprovalModal, setShowApprovalModal] = useState(false)

  return (
    <>
      <Button
        disabled={approvalDisabled}
        color="success"
        className="text-center text-white"
        onClick={(e) => {
          e.preventDefault()
          setShowApprovalModal(true)
        }}
        data-testid="unit-page-send-for-approval-button"
      >
        <FontAwesomeIcon icon={faPaperPlane} fixedWidth />
        <div>Send for approval</div>
      </Button>
      {unit && (
        <ApproveModal
          {...unit}
          isOpen={showApprovalModal}
          setUnitCallback={setUnitCallback}
          isOpenCallback={setShowApprovalModal}
        />
      )}
    </>
  )
}
