import React, { useMemo } from 'react'
import { Card, CardBody, CardFooter, Col, Progress, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  CentreUnit,
  getSubTitle,
  Title,
  unitInProgress,
  useAuth0,
  ValuationStatus,
} from '../../common'
import { CandidateStats, StatusAndControlBlock } from './components'

import './unit-progress.scss'

interface Props {
  onUpdate?: () => void
}

export const UnitProgress: React.FC<
  Props & CentreUnit & React.HTMLAttributes<HTMLDivElement>
> = ({
  className,
  id,
  unitName,
  unitId,
  totalCandidates,
  gradedCandidates,
  centreId,
  valuationStatus,
  percentageCompleted,
  closed,
  onUpdate,
  deadline,
  extendedDeadline,
}): JSX.Element => {
  const inProgress = unitInProgress(valuationStatus)
  const is = useMemo(() => (status: string) => valuationStatus === status, [
    valuationStatus,
  ])
  const { user } = useAuth0()

  const unitTemplateFileName = useMemo(() => `${centreId}_${unitId}.csv`, [
    centreId,
    unitId,
  ])

  const title = useMemo(() => unitName || unitId, [unitName, unitId])
  const subTitle = useMemo(() => getSubTitle(unitName, unitId), [
    unitName,
    unitId,
  ])

  return (
    <div className="unit-progress">
      <Link
        className="no-text-hover"
        to={`/centres/${centreId}/unit/${id}`}
        data-testid={`unit-progress-${id}`}
      >
        <Card
          className={`${className} project-row transition-none`}
          data-testid="unit-card"
        >
          <CardBody
            className={`p-42 ${inProgress ? '' : 'bg-lightButNotTooLight'}`}
          >
            <Row className="align-items-center justify-content-between">
              <Col>
                <Title title={title} subTitle={subTitle} />
              </Col>
              <Col xs="auto" className="text-right pl-3">
                <CandidateStats
                  id={id}
                  gradedCandidates={gradedCandidates}
                  totalCandidates={totalCandidates}
                />
              </Col>
              <StatusAndControlBlock
                title={title}
                subTitle={subTitle}
                id={id}
                centreId={centreId}
                totalCandidates={totalCandidates}
                gradedCandidates={gradedCandidates}
                inProgress={inProgress}
                user={user}
                unitTemplateFileName={unitTemplateFileName}
                closed={closed}
                is={is}
                onUpdate={onUpdate}
                deadline={deadline}
                extendedDeadline={extendedDeadline}
              />
            </Row>
          </CardBody>
          {(is(ValuationStatus.INCOMPLETE) || is(ValuationStatus.COMPLETE)) && (
            <CardFooter className="p-0 border-0">
              <Progress
                className="progress-slim-1 rounded-top-0 rounded-bottom-sm-down"
                max={100 || 0}
                value={percentageCompleted || 0}
              />
            </CardFooter>
          )}
        </Card>
      </Link>
    </div>
  )
}
