import React, { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import {
  escapeSlashes,
  getColumnSpecificationsHeight,
  LetterWithSpecifications,
  Loading,
  toLetterWithSpecification,
  useSpecificationUnits,
} from '../../common'

import './specifications-to-qualification.scss'

interface Props {
  qualification: string
}

export const SpecificationToQualification: FC<Props> = ({ qualification }) => {
  const history = useHistory()

  const navigateToSpecification = (specification: string) =>
    history.push(`/aam/${qualification}/${escapeSlashes(specification)}`)

  const { result, inProgress } = useSpecificationUnits(qualification)

  const letterWithSpecificationsList: LetterWithSpecifications[] = useMemo(
    () => (result ? toLetterWithSpecification(result) : []),
    [result]
  )

  const height = useMemo(
    () => ({
      height: getColumnSpecificationsHeight(letterWithSpecificationsList),
    }),
    [letterWithSpecificationsList]
  )

  if (!result || inProgress) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <div>
      <div className="specifications-to-qualification-content container">
        <strong className="header">{`${qualification} Specifications`}</strong>
        <p className="sub-header">
          Click on a specification to view the units/components that have
          additional assessment material within that specification.{' '}
        </p>
        <div style={height} className="body">
          {letterWithSpecificationsList.map((el) => (
            <div key={el.letter} className="specifications-for-letter">
              <strong
                className="specifications-letter"
                data-testid={`specifications-letter-${el.letter}`}
              >
                {el.letter}
              </strong>
              {el.items.map((specification, index) => (
                <div
                  key={specification}
                  className="specification-name"
                  data-testid={`spec-${index}`}
                  onClick={() => navigateToSpecification(specification)}
                >
                  {specification}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
