import React, { useMemo } from 'react'
import { Spinner } from 'reactstrap'
import { snakeCase } from '../../services'
import { TargetMetNotificaton } from './target-met-notificaton'

interface Props {
  isActive?: boolean
  countPending?: boolean
  label: string
  count?: number
  outOfCount?: number
  errorMessage?: string
  className?: string
}

export const TabContentLabelWithCount: React.FC<Props> = ({
  isActive = false,
  label,
  countPending = false,
  outOfCount,
  errorMessage,
  count,
  className,
}): JSX.Element => {
  const syleClasses = useMemo(() => {
    if (isActive) {
      return 'bg-primary text-white'
    }
    if (count === 0) {
      return 'bg-white border text-dark'
    } else {
      return 'bg-lightButNotTooLight text-dark'
    }
  }, [isActive, count])

  return (
    <div
      data-testid={`tab-${snakeCase(label)}`}
      className={`${syleClasses} rounded font-weight-bold py-2 px-25 position-relative ${className}`}
    >
      {errorMessage && (
        <TargetMetNotificaton targetNotMetMessage={errorMessage} />
      )}
      <div data-testid="unit-filter" className="text-center">
        <div
          data-testid="unit-filter-grade"
          className="tab-content-label font-larger"
        >
          {label}
        </div>

        {count !== undefined && (
          <div
            data-testid={`unit-filter-${label.toLowerCase()}`}
            className={`${
              !isActive ? 'text-secondary' : ''
            } h5 mb-0 font-weight-bold`}
          >
            {countPending && (
              <Spinner
                className="font-normal"
                color={isActive ? 'white' : 'secondary'}
                size="sm"
              />
            )}
            {!countPending && (
              <span>
                {count} {outOfCount && ` / ${outOfCount}`}{' '}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
