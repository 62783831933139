import React from 'react'
import { Container } from 'reactstrap'
import { CentreUnit } from '../../../../common'
import { SubjectPageHeaderEditMode } from './subject-page-header-edit-mode'
import { SubjectPageHeaderReadOnly } from './subject-page-header-read-only'

interface Props {
  unit: CentreUnit | undefined
  viewOnly: boolean
  showButtons: boolean
  approvalDisabled: boolean
  viewOnlyState: string
  onUploadComplete: (uploadedWithErr: boolean) => void
  setUnitCallback: (unit: CentreUnit | undefined) => void
}

export const SubjectPageHeader: React.FC<Props> = ({
  unit,
  viewOnly,
  showButtons,
  approvalDisabled,
  viewOnlyState,
  onUploadComplete,
  setUnitCallback,
}) => {
  return (
    <>
      <Container>
        {unit && !viewOnly && (
          <SubjectPageHeaderEditMode
            unit={unit}
            showButtons={showButtons}
            approvalDisabled={approvalDisabled}
            viewOnly={viewOnly}
            onUploadComplete={onUploadComplete}
            setUnitCallback={setUnitCallback}
          />
        )}

        {unit && viewOnly && (
          <SubjectPageHeaderReadOnly
            viewOnlyState={viewOnlyState}
            unit={unit}
          />
        )}
      </Container>
    </>
  )
}
