import React, { useMemo } from 'react'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownload } from '@fortawesome/pro-solid-svg-icons'
import { FileImageService, usePresignedDownload } from '../../common'

import './guidance-doc-download.scss'

interface Props {
  fileName: string
  downloadLink: string
  dataTestIdSuffix: string
}

export const GuidanceDocDownload: React.FC<Props> = ({
  fileName,
  downloadLink,
  dataTestIdSuffix,
}) => {
  const fileIcon = useMemo(
    () => FileImageService.getFileImageByFileName(fileName),
    [fileName]
  )

  const { pending, download } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/${downloadLink}`,
    fileName,
  })

  return (
    <Row className="guidance-doc__container mx-auto">
      <Col
        className="guidance-doc__file-name-container"
        data-testid={`guidance-doc-filename-${dataTestIdSuffix}`}
      >
        <FontAwesomeIcon
          icon={fileIcon}
          className="guidance-doc__file-name-icon"
        />
        {fileName}
      </Col>
      <Col>
        <Button
          className="guidance-doc__download-button"
          data-testid={`guidance-doc-download-button-${dataTestIdSuffix}`}
          disabled={pending}
          onClick={download}
        >
          <span>
            {pending ? (
              <Spinner className="mr-2" size="sm" />
            ) : (
              <FontAwesomeIcon
                icon={faCloudDownload}
                className="guidance-doc__download-icon"
              />
            )}
            Download
          </span>
        </Button>
      </Col>
    </Row>
  )
}
